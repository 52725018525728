// Chat.js

import React, { useState, useRef, useEffect } from 'react';
import './Chat.css';
import { GoogleGenerativeAI, HarmCategory, HarmBlockThreshold } from '@google/generative-ai';

const Chat = () => {
    const genAI = new GoogleGenerativeAI(process.env.REACT_APP_API_KEY);
    const [messages, setMessages] = useState([]);
    const [newMessage, setNewMessage] = useState('');
    const messageContainerRef = useRef(null);

    const handleInputChange = (e) => {
        setNewMessage(e.target.value);
    };

    const handleSendMessage = async () => {
        if (newMessage.trim() === '') return;

        setMessages((prevMessages) => [
            ...prevMessages,
            { text: newMessage, sender: 'user' },
        ]);
        setNewMessage('');

        const generationConfig = {
            temperature: 0.9,
            topK: 1,
            topP: 1,
            maxOutputTokens: 2048,
        };

        const safetySettings = [
            {
                category: HarmCategory.HARM_CATEGORY_HARASSMENT,
                threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
            },
            {
                category: HarmCategory.HARM_CATEGORY_HATE_SPEECH,
                threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
            },
            {
                category: HarmCategory.HARM_CATEGORY_SEXUALLY_EXPLICIT,
                threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
            },
            {
                category: HarmCategory.HARM_CATEGORY_DANGEROUS_CONTENT,
                threshold: HarmBlockThreshold.BLOCK_MEDIUM_AND_ABOVE,
            },
        ];

        const parts = [
            { text: newMessage },
        ];
        const model = genAI.getGenerativeModel({ model: "gemini-pro" });
        const result = await model.generateContent({
            contents: [{ role: "user", parts }],
            generationConfig,
            safetySettings,
        });
        const response = await result.response;
        try {
            const text = response.text();
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: text, sender: 'Gemini' },
            ]);
        } catch (error) {
            const text = "I'm sorry!";
            setMessages((prevMessages) => [
                ...prevMessages,
                { text: text, sender: 'Gemini' },
            ]);
        }
    };

    const handleKeyPress = (e) => {
        if (e.key === 'Enter') {
            handleSendMessage();
        }
    };

    // Auto-scroll to the bottom when messages are updated
    useEffect(() => {
        if (messageContainerRef.current) {
            messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
        }
    }, [messages]);

    return (
        <div className="chat-container">
            <h1 className="chat-heading">Chat with Gemini <br/>(Google AI)</h1>
            <div className="message-container" ref={messageContainerRef}>
                {messages.map((message, index) => (
                    <div
                        key={index}
                        className={`message ${message.sender === 'user' ? 'user-message' : 'other-message'
                            }`}
                    >
                        <span className="bold-username">
                            {message.sender === 'user' ? 'You' : message.sender}
                        </span>: {message.text}
                    </div>
                ))}
            </div>
            <div className="input-container">
                <input
                    type="text"
                    value={newMessage}
                    onChange={handleInputChange}
                    onKeyPress={handleKeyPress}
                    placeholder="Type your message..."
                    className="message-input"
                />
                <button onClick={handleSendMessage} className="send-button">
                    Send
                </button>
            </div>
        </div>
    );
};

export default Chat;
